import React, { useState } from "react";
import { connectHits } from "react-instantsearch-dom";
import Hit from "./hit";
import KimballHit from "./custom/kimballHit";
import AutocompleteWithPartModels from "./autocomplete/autocomplete-with-part-models";

const Hits = ({ hits, defaultPicture, custom }) => {
  // PUT THE IS MOBILE LOGIC HERE
  const [partOnHover, setPartOnHover] = useState("")
  return (
    <>
      <div className="ais-Hits" style={custom==="kimball"? {display: 'grid', gridTemplateColumns: '63% 33%', gap: '4%'}: {}} >
        <ol className="unified-custom-hits">
          {hits.map((hit) => (
            <li key={hit.id}
            //  onMouseEnter={()=>setPartOnHover(hit.sku)} temporarily disabled this feature, reserved for later use.
             >

              {
                custom === "kimball" &&
                <KimballHit hit={hit} defaultPicture={defaultPicture} custom={custom} />
              }

              {
                custom === "none" || custom === "osg" &&
                <Hit hit={hit} defaultPicture={defaultPicture} />
              }
              
            </li>
          ))}
        </ol>
        {
          custom === "kimball" &&
          <AutocompleteWithPartModels partOnHover={partOnHover} setPartOnHover={setPartOnHover}/>
        }
      </div>
    </>
  );
};

export default connectHits(Hits);
