import React from "react";
import Autocomplete from "./autocomplete/autocomplete";
import AutocompleteKimball from "./autocomplete/autocomplete-kimball";

const searchButton = ({
  openModal,
  defaultPicture,
  custom
}) => {
  
  return (
    <>
      <div style={{ position: "relative" }}>
        {
          (custom === "kimball")?
          <AutocompleteKimball openModal={openModal} defaultPicture={defaultPicture} custom={custom} />
          :
          <Autocomplete openModal={openModal} defaultPicture={defaultPicture} custom={custom} />
        }
      </div>
    </>
  );
};

export default searchButton;
