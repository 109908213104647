import { Configure } from "react-instantsearch-dom";
import { Index } from "react-instantsearch-dom";
import { connectAutoComplete } from "react-instantsearch-dom";
import Models from "./models";
import { Hits } from "react-instantsearch-dom";
import { useMemo } from "react";

const AutoCompletewithPartModels = ({ currentRefinement, partOnHover, setPartOnHover }) => {
    const modelIndex = (window.location.href.includes('sandbox') || window.location.href.includes('localhost'))? "kimball_dev_models": "kimball_prod_models"

    useMemo(() => {
        setPartOnHover(currentRefinement)
    }, [currentRefinement])

    return(
        <div>
            <div
                style={{textAlign: 'left', justifyContent: 'center', marginTop: '10px', fontSize: '1.1rem', fontWeight: 'bolder', color: '#1e71cd', marginLeft: '0.5rem'}}
            >
                Model Breakdowns for {partOnHover}
            </div>
            <div className='aa-PanelLayout aa-Panel--scrollable' style={{width: '20rem'}}>
                <section className='aa-Source'>
                    <Index indexName={modelIndex}>
                        <Configure
                            query={partOnHover}
                            hitsPerPage={250}
                        />
                        <Models
                            hits={Hits}
                        />
                    </Index>
                </section>
            </div>
        </div>
    )
}

export default connectAutoComplete(AutoCompletewithPartModels)