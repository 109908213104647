import { useMemo } from "react";
import TypesenseInstantSearchAdapter from "typesense-instantsearch-adapter";

export function typesenseSearchClient(config) {

  const searchClient = useMemo(() => {
    const client = new TypesenseInstantSearchAdapter({
        server: {
            apiKey: config.server.key, // Be sure to use an API key that only allows search operations
            nodes: [
            {
                host: config.server.host,
                port: config.server.port,
                path: '', // Optional. Example: If you have your typesense mounted in localhost:8108/typesense, path should be equal to '/typesense'
                protocol: "https",
            },
            ],
            cacheSearchResultsForSeconds: 3 * 60, // Cache search results from server. Defaults to 2 minutes. Set to 0 to disable caching.
        },
         // The following parameters are directly passed to Typesense's search API endpoint.
        //  So you can pass any parameters supported by the search endpoint below.
        //  query_by is required.
        ...( config.custom != "kimball" && {additionalSearchParameters: {
          ...config.options
        }}),
        ...(config.custom == "kimball" && {
          collectionSpecificSearchParameters: {
            ...config.options.collectionSpecificSearchParameters,
          }
        }),
        geoLocationField: config.coords ? config.coords.geoloc : null // Used for GeoSearch
    });

    return client
  }, [config]);

  return searchClient;
}
