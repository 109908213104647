import React from 'react'
import { connectHits, Highlight } from 'react-instantsearch-dom'

const Products = ({ hits, defaultPicture, custom, setPartOnHover }) => {
    const getReplaceData = (customFields, custom) => {
        let customData = {}
        if(custom == "kimball"){
            const objArr = customFields
            customData = objArr.filter(i=>{return i.name === "Replaces"})[0]
        }
        return customData?.value? customData: null
    }

    const getSubstituteData = (customFields, custom) => {
        let customData = {};
        if(custom === "osg") {
        const objArr = JSON.parse(customFields);
        customData = objArr.filter(i => { return i.name === "Substitute Item"; })[0];
        }

        return customData?.value ? customData : null;
    }

    const onPartHover = (hit) => {
        setPartOnHover(hit.sku)
    }

  return (
    <ul
        className='aa-List'
        style={{
            display: "flex",
            flexFlow: "column"
        }}
    >
        {hits.slice(0, 6).map(hit => (
            <li key={hit.id}
                className='aa-Item'
                style={{  border: 'solid 1px #dedede', marginTop: '2px', marginBottom: '2px'}}
                // onMouseEnter={()=>onPartHover(hit)} temporarily disabled this feature
            >
                <a
                    className='aa-ItemLink'
                    href={hit.url}
                    style={{padding: "5px 5px 5px 5px"}}
                >
                    <div
                        className='aa-ItemContentBody'
                    >
                        <div className='aa-ItemContent'>
                            <div className='aa-ItemIcon aa-ItemIcon--picture aa-ItemIcon--alignTop'>
                                <img
                                    src={hit.image? hit.image: defaultPicture}
                                    alt={hit.name}
                                    height="20"
                                    width="20"
                                />
                            </div>
                            <div className='aa-ItemContentBody'>
                                <div className='aa-ItemContentTitle'>
                                    <Highlight hit={hit} attribute="name" tagName="mark" />
                                    {/* <h5>{hit.name}</h5> */}
                                </div>
                                <div className='aa-ItemContentDescription'>
                                    {getReplaceData(hit.customFields, custom)?
                                        (<>
                                            <strong>Replaces: </strong> {getReplaceData(hit.customFields, custom).value}
                                            <br/>
                                        </>):
                                        (<>{" "}</>)
                                    }
                                    {getSubstituteData(hit.customFields, custom) ?
                                        (<>
                                            <strong>Alternatives: </strong> {getSubstituteData(hit.customFields, custom).value}
                                            <br />
                                        </>) : (<>{""}</>)
                                    }
                                    { hit.brand ? (
                                        <>Brand: <strong>{hit.brand}</strong> <br /></>
                                        ) :
                                        (<>{""}</>)
                                    }
                                    { hit.categories.length ? (
                                        <>Categories: <strong>{hit.categories[0]}</strong> <br /></>
                                        ) :
                                        (<>{""}</>)
                                    }
                                </div>
                                <div
                                className='aa-ItemContentDescription'
                                >
                                    <strong style={{ fontSize: '1.2rem'}}>${hit.price.toLocaleString(undefined, {
                                        minimumFractionDigits: 2,
                                        maximumFractionDigits: 2
                                        })}</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                </a>
            </li>
        ))
        }
    </ul>
  )
}

export default connectHits(Products)