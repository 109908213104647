import React, { useMemo, useState } from 'react'
import { Stats } from 'react-instantsearch-dom'
import { Configure } from 'react-instantsearch-dom'
import { Index } from 'react-instantsearch-dom'
import { Hits } from 'react-instantsearch-dom'
import Models from './models'
import Products from './products'

const AutocompleteHitsWithParts = ({ hits, currentRefinement, openModal, refine, defaultPicture, custom}) => {
    const [partOnHover, setPartOnHover] = useState(currentRefinement)
    const productIndex = (window.location.href.includes('sandbox') || window.location.href.includes('localhost'))? "kimball_dev_products": "kimball_prod_products"
    const modelIndex = (window.location.href.includes('sandbox') || window.location.href.includes('localhost'))? "kimball_dev_models": "kimball_prod_models"

    const noResult = () => {
        refine("")
        openModal()
    }

    useMemo(()=> {
        setPartOnHover(currentRefinement)
    }, [currentRefinement])

    let hitLists = (
        <>
            <div>
                <div>
                    No result for query "{currentRefinement}"
                </div>
                <div onClick={noResult}>
                    <strong>See all products</strong>
                </div>
            </div>
        </>
    )

    if(hits.length){
        hitLists = (
            <>
                <div className='flex'>
                    <div style={{width:'60%', background: '#eaf6f8'}}>
                        <div style={{textAlign: 'left', justifyContent: 'center', marginTop: '5px', fontSize: '1.15rem', fontWeight: 'bolder', color: '#1e71cd', marginLeft: '0.7rem'}}>Parts</div>
                        <div
                            className='aa-PanelLayout aa-Panel--scrollable'
                        >
                            <section className='aa-Source'>
                            <Index indexName={productIndex}>
                                <Configure query={currentRefinement} />
                                <Products hits={Hits} defaultPicture={defaultPicture} custom={custom} setPartOnHover={setPartOnHover} />
                            </Index>
                            </section>
                        </div>
                    </div>
                    <div style={{width:'40%', background: '#eaf6f8'}}>
                        <div
                            style={{textAlign: 'left', justifyContent: 'center', marginTop: '10px', fontSize: '1.1rem', fontWeight: 'bolder', color: '#1e71cd', marginLeft: '0.5rem'}}
                        >
                            Model Breakdowns
                        </div>
                        <div className='aa-PanelLayout aa-Panel--scrollable'>
                            <section className='aa-Source' >
                                <Index indexName={modelIndex}>
                                    <Configure
                                        query={partOnHover}
                                        hitsPerPage={250}
                                    />
                                    <Models
                                        hits={Hits}
                                     />
                                </Index>
                            </section>
                        </div>
                    </div>
                </div>
                <div className='autocomplete-footer'>
                    <div
                        className='autocomplete-footer__stats'
                        style={{fontWeight: "normal"}}
                        onClick={openModal}
                    >
                    <strong>
                        <Stats
                            translations={{
                            stats(nbHits) {
                                return `See all matching results for "${currentRefinement}" (${nbHits.toLocaleString()})`;
                            },
                            }}
                        />
                    </strong>
                    </div>
                </div>
            </>
        )
    }

    if(currentRefinement.length === 0){
        return <></>
    }

    return (
        <div className="aa-Panel">{hitLists}</div>
    )
}

export default AutocompleteHitsWithParts