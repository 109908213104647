import React from "react";
import { Highlight, Snippet } from "react-instantsearch-dom";

const KimballHit = ({ hit, defaultPicture, custom }) => {

  let customData = {};
  if(custom === "kimball") {
    const objArr = hit.customFields;
    customData = objArr.filter(i => { return i.name === "Replaces"; })[0];
  }

  return (
    <>
      <article className="unified-hits">
        <a href={hit.url} className="unified-hits__inner">
          <div className="unified-hits__inner__image-container">
            <img src={hit.image ? hit.image : defaultPicture} alt={hit.name} />
          </div>
          <div className="unified-hits__inner__body">
            <header className="unified-hits__inner__body__header">
              <h1 className="unified-hits__inner__body__header--title">
                <Highlight attribute="name" tagName="mark" hit={hit} />
              </h1>
            </header>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                height: "-webkit-fill-available"
              }}
            >
              
              <p className="unified-hits__inner__body__description unified-hits__inner__body--ellips">
                {
                    (custom === "kimball" && customData?.value) &&
                    <span>
                      <strong>Replaces:</strong>{ customData.value }
                    </span>
                }
              </p>
              <footer>
                <span className="unified-hits__inner__body--currency">$</span>
                <span className="unified-hits__inner__body--price">
                  {hit.price.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })
                  }
                </span>
              </footer>
            </div>
          </div>
        </a>
      </article>
    </>
  );
};

export default KimballHit;
