import React from 'react'
import { Highlight } from 'react-instantsearch-dom'
import { connectHits } from 'react-instantsearch-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouseDamage, faFilePdf } from '@fortawesome/free-solid-svg-icons'

const Models = ({ hits }) => {
  return (
    <ul
      className='aa-List'
      style={{
          display: "flex",
          flexFlow: "column"
      }}
    >
      {
        hits.map(hit=>(
            <li
              key={hit.id}
              style={{ paddingTop: '0.3rem', paddingBottom: '0.3rem'}}
            >
                <a
                  target='_blank'
                  href={hit.url}
                  style={{padding: "0",  textAlign: 'left', justifyContent: 'center'}}
                >
                  <div
                    className='text-gray-500 hover:text-gray-600 cursor-pointer focus:outline-none focus:ring focus:ring-gray-700 text-xl font-bold hover:scale-150 ml-5'
                  >
                    {/* <FontAwesomeIcon icon={faFilePdf} className='mr-3' /> */}
                    <Highlight attribute="model" hit={hit}/>
                  </div>
                </a>
            </li>
        ))
      }
    </ul>
  )
}

export default connectHits(Models)